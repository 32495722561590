<template>
  <checklist />
</template>

<script>
import Checklist from '@components/shared/checklist/checklist';
export default {
  name: 'DriverChecklist',
  data () {
    return {}
  },
  components: {
    Checklist
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
